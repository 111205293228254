import {debounce} from 'lodash'
import {useLayoutEffect, useState} from 'react'

const useScreenDetector = () => {
	const [width, setWidth] = useState(window.innerWidth)
	const [height, setHeight] = useState(window.innerHeight)

	const [isMinMobile, setIsMinMobile] = useState(width < 360)
	const [isMobile, setIsMobile] = useState(width >= 360 && width < 768)
	const [isTablet, setIsTablet] = useState(width >= 768 && width < 1000)
	const [isMinDesktop, setIsMinDesktop] = useState(width > 1000 && width <= 1140)
	const [isDesktop, setIsDesktop] = useState(width > 1140)

	useLayoutEffect(() => {
		const handleWindowSizeChange = () => {
			const newWidth = window.innerWidth
			const newHeight = window.innerHeight
			if (newWidth !== width || newHeight !== height) {
				setWidth(newWidth)
				setHeight(newHeight)
				setIsMinMobile(newWidth < 360)
				setIsMobile(newWidth >= 360 && newWidth < 768)
				setIsTablet(newWidth >= 768 && newWidth < 1000)
				setIsMinDesktop(newWidth > 1000 && newWidth <= 1140)
				setIsDesktop(newWidth > 1140)

				// console.log('screenDetector ', width, 'x', height, isMobile, isMinMobile, isTablet, isMinDesktop, isDesktop)
			}
		}
		window.addEventListener('resize', debounce(handleWindowSizeChange, 250))
		return () => window.removeEventListener('resize', handleWindowSizeChange)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return {isMinMobile, isMobile, isTablet, isMinDesktop, isDesktop, width, height}
}
export default useScreenDetector
