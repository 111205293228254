/* eslint-disable no-unused-vars */
import React, {useState, useRef, useEffect} from 'react'
import {basicGalleryImages, ForGallery, Gallery_ShowBoy_lg, premiumGalleryImages, standardGalleryImages} from '../../images'
import ImageLoader from '../../utils/imageLoader' // Import the ImageLoader component
import './index.css'

// Import additional doodle images
// import GalleryBlackWhiteLeftPNG from '../../images/gallery/GalleryBlackWhiteLeft.png'
import GalleryBlackWhiteLeftWEBP from '../../images/gallery/doodles/GalleryBlackWhiteLeft.webp'
// // import GalleryBlackWhiteRightPNG from '../../images/gallery/doodles/GalleryBlackWhiteRight.png'
import GalleryBlackWhiteRightWEBP from '../../images/gallery/doodles/GalleryBlackWhiteRight.webp'
// // import GalleryColorLeftPNG from '../../images/gallery/doodles/GalleryColorLeft.png'
import GalleryColorLeftWEBP from '../../images/gallery/doodles/GalleryColorLeft.webp'
// // import GalleryColorRightPNG from '../../images/gallery/doodles/GalleryColorRight.png'
import GalleryColorRightWEBP from '../../images/gallery/doodles/GalleryColorRight.webp'
// // import GalleryPainterlyLeftPNG from '../../images/gallery/doodles/GalleryPainterlyLeft.png'
import GalleryPainterlyLeftWEBP from '../../images/gallery/doodles/GalleryPainterlyLeft.webp'
// // import GalleryPainterlyRightPNG from '../../images/gallery/doodles/GalleryPainterlyRight.png'
import GalleryPainterlyRightWEBP from '../../images/gallery/doodles/GalleryPainterlyRight.webp'

import CustomGlobalButton from '../../components/CustomGlobalButton'
import GalleryGrid from './GalleryGrid'
import useScreenDetector from '../../hooks/useScreenDetector'
// import {useNavigate} from 'react-router-dom'
// import {scrollIntoView} from '../../utils/scroll_into_view'

const GallerySection = ({section}) => {
	const {title: sectionTitle, subTitle, images, text, useFor, darkTheme, highlights, doodles, textOnTop, textClass} = section
	const galleryRef = useRef(null)

	const {isMobile, isMinDesktop, isTablet, isMinMobile, isDesktop} = useScreenDetector()
	// darkmode needs background image
	return (
		<div className='relative w-screen'>
			{darkTheme ? (
				<>
					<div className={`absolute inset-0 bg-black`}></div>
					<div
						className={`z-0 bg-black opacity-25 blur-sm absolute inset-0 bg-cover bg-center`}
						style={{
							backgroundImage: `url('${images[0]?.webp}')`,
						}}
					/>
				</>
			) : (
				<></>
			)}
			<div className={`relative gallery-section w-full mx-auto max-w-screen-xl py-6`}>
				<div id={sectionTitle} ref={galleryRef}>
					<GalleryGrid images={images} aspectSquare={!darkTheme} />
				</div>

				<h2 className={`${darkTheme ? 'text-white' : 'text-black'} mt-8 text-4xl font-lucky`}>
					{sectionTitle}
					<span className={`opacity-70 text-2xl text-e_gray`}>/{subTitle}</span>
				</h2>
				{textOnTop && (
					<p
						className={`${
							darkTheme ? 'text-white' : 'text-black'
						} lg:text-xl w-full md:w-3/5 text-center md:text-start mt-8 flex-grow px-10 md:-pr-10 ${textClass}`}>
						{text}
					</p>
				)}
				<div className='info-section'>
					{!isMobile && !isMinMobile && (
						<ImageLoader
							altTitle='Doodle Left'
							artPNG={doodles.left?.png}
							artWEBP={doodles.left.webp}
							className={`hidden md:block ${doodles.left.extraClass}`}
						/>
					)}
					<div className='flex flex-col text-center justify-center items-center  '>
						{!textOnTop && (
							<p
								className={`${darkTheme ? 'text-white' : 'text-black'} lg:text-xl w-full ~text-center md:text-start self-start flex-grow px-10 ${textClass}`}>
								{text}
							</p>
						)}
						{(isMobile || isMinMobile) && (
							<ImageLoader
								altTitle='Doodle Mobile'
								artPNG={doodles.left?.png}
								artWEBP={doodles.left.webp}
								className={`w-full md:hidden block px-4 max-w-xs ${darkTheme ? 'order-last mt-4 md:-mt-[40px]' : 'mt-4'}`}
							/>
						)}
						{useFor ? <ForGallery className={`${darkTheme ? 'invert' : ''} rotate-[25deg] w-16 h-16 mt-4 md:mt-12`} /> : <></>}
						<ul className='font-lucky text-e_red text-2xl mt-2 text-left'>
							{highlights.map((t, key) => {
								return <li key={key}>· {t.toUpperCase()}</li>
							})}
						</ul>

						{
							<CustomGlobalButton
								getItem={subTitle}
								from={'gallery'}
								gallery={darkTheme ? false : true}
								galleryPremium={darkTheme ? true : false}
								className='my-8'
							/>
						}
					</div>
					{!isMobile && !isMinMobile && (
						<ImageLoader
							altTitle='Doodle Right'
							artPNG={doodles.right?.png}
							artWEBP={doodles.right.webp}
							className={`hidden md:block ${doodles.right.extraClass}`}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

const gallerySections = (images) => [
	{
		images: images.basic,
		highlights: ['GRADUATION MOMENTO', 'INVITATIONS', 'JUST BECAUSE'],
		text: 'Custom caricature portrait, stylized in that classic "cartoony" feel!',
		useFor: true,
		title: 'BLACK & WHITE',
		subTitle: 'BASIC',
		doodles: {
			right: {
				// png: GalleryBlackWhiteRightPNG,
				webp: GalleryBlackWhiteRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryBlackWhiteLeftPNG,
				webp: GalleryBlackWhiteLeftWEBP,
				extraClass: '',
			},
		},
		textOnTop: false,
		textClass: 'lg:px-24',
	},
	{
		images: images.standard,
		highlights: ['BIRTHDAY', 'ANNIVERSARY', 'FAMILY', 'WEDDING GIFT', 'FAVORITE PET'],
		text: (
			<>
				Elevate your caricature to the next level with, personalized details and expressive colors that,
				<br />
				instantly bring life to your caricature, creating an everlasting impression.
			</>
		),
		useFor: true,
		title: 'COLOR',
		subTitle: 'STANDARD',
		doodles: {
			right: {
				//png: GalleryColorRightPNG,
				webp: GalleryColorRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryColorLeftPNG,
				webp: GalleryColorLeftWEBP,
				extraClass: '',
			},
		},
		textOnTop: true,
		textClass: '',
	},
	{
		images: images.premium,
		highlights: [],
		darkTheme: true,
		text: (
			<>
				High-end cinematic style fine-art piece that captures the heart of your family.
				<br />
				<br />
				An exceptionally meaningful gift that, transcends the capabilities of the camera, one that brings out the inner charm, in captivating painterly
				cinematic fashion. A timeless keepsake your family can cherish forever.
			</>
		),
		useFor: false,
		title: 'FINE ART',
		subTitle: 'PREMIUM',
		doodles: {
			right: {
				//png: GalleryPainterlyRightPNG,
				webp: GalleryPainterlyRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryPainterlyLeftPNG,
				webp: GalleryPainterlyLeftWEBP,
				extraClass: '',
			},
		},
		textOnTop: false,
		textClass: '!text-justify',
	},
]

const Gallery3 = () => {
	const [allImages, setGalleryImages] = useState({
		basic: [],
		standard: [],
		premium: [],
	})

	useEffect(() => {
		const loadImages = async () => {
			setGalleryImages({
				basic: basicGalleryImages,
				standard: standardGalleryImages,
				premium: premiumGalleryImages,
			})
		}

		loadImages()
	}, [])

	return (
		<>
			<div id='gallery' className='relative flex flex-col justify-start text-center items-center ~min-h-[100vh] w-screen'>
				{/* <div className='hidden md:flex justify-end w-full h-auto md:absolute right-0 top-0 z-10 md:-translate-y-[40%] pointer-events-none select-none touch-none'>
				<img data-src={Gallery_ShowBoy_lg} alt='Gallery Show Boy' className='doodle max-w-[40vw] h-full md:max-w-[30vw]' />
				</div> */}
				<h1 className='section-title'>GALLERY</h1>
				{gallerySections(allImages).map((section, key) => {
					return (
						<div key={key}>
							<GallerySection section={section} />
						</div>
					)
				})}
			</div>
		</>
	)
}

export default Gallery3
