/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useRef, useState} from 'react'
// import { useEffect, useState } from 'react';
import Border from '../components/Border'
// import {
// 	Char_AllSmiles,
// 	Char_AxlRose,
// 	Char_BradPitt,
// 	Char_CoolBlackLady,
// 	Char_JimCarry,
// 	Char_TimCha,
// 	Hero_ShowGirl,
// 	HeroBG_Chars_D,
// 	HeroBG_Chars_M,
// 	HeroBG_D,
// 	HeroBG_M,
// 	LogoTxt,
// } from '../img'

// import BG_D_full from '../images/hero/HeroBG.D_full.1861x1373.png'
// import BG_D_PNG from '../images/hero/HeroBG.D.1861x1373.png'
// import BG_D_JPG from '../images/hero/HeroBG.D.1861x1373.jpeg'
// import BG_D_WEBP from '../images/hero/HeroBG.D.1861x1373.webp'
// import BG_Dmin_PNG from '../images/hero/HeroBG.Dmin.1543x1283.png'
// import BG_Dmin_JPG from '../images/hero/HeroBG.Dmin.1543x1283.jpeg'
// import BG_Dmin_WEBP from '../images/hero/HeroBG.Dmin.1543x1283.webp'

// import BG_M_full from '../images/hero/HeroBG.M_full.1095x2183.png'
// import BG_M_PNG from '../images/hero/HeroBG.M.822x1638.png'
// import BG_M_JPG from '../images/hero/HeroBG.M.822x1638.jpeg'
// import BG_M_WEBP from '../images/hero/HeroBG.M.822x1638.webp'
// import BG_Mmin_PNG from '../images/hero/HeroBG.Mmin.744x1556.png'
// import BG_Mmin_JPG from '../images/hero/HeroBG.Mmin.744x1556.jpeg'
// import BG_Mmin_WEBP from '../images/hero/HeroBG.Mmin.744x1556.webp'

import Hero_maxDesktop_BG_JPG from '../images/hero/v3/Hero_maxDesktop_BG-optimised.jpeg'
import Hero_maxDesktop_BG_WEBP from '../images/hero/v3/Hero_maxDesktop_BG-optimised.webp'
import Hero_minDesktop_BG_WEBP from '../images/hero/v3/Hero_minDesktop_BG-optimised.webp'
import Hero_minDesktop_BG_JPG from '../images/hero/v3/Hero_minDesktop_BG-optimised.jpeg'

import Hero_maxDesktop_Char_PNG from '../images/hero/v3/Hero_maxDesktop_Char-optimised.png'
import Hero_maxDesktop_Char_WEBP from '../images/hero/v3/Hero_maxDesktop_Char-optimised.webp'
import Hero_minDesktop_Char_WEBP from '../images/hero/v3/Hero_minDesktop_Char-optimised.webp'
import Hero_minDesktop_Char_PNG from '../images/hero/v3/Hero_minDesktop_Char-optimised.png'

import Hero_maxMobile_BG_JPG from '../images/hero/v3/Hero_maxMobile_BG-optimised.jpeg'
import Hero_maxMobile_BG_WEBP from '../images/hero/v3/Hero_maxMobile_BG-optimised.webp'
import Hero_minMobile_BG_WEBP from '../images/hero/v3/Hero_minMobile_BG-optimised.webp'
import Hero_minMobile_BG_JPG from '../images/hero/v3/Hero_minMobile_BG-optimised.jpeg'

import Hero_maxMobile_Char_PNG from '../images/hero/v3/Hero_maxMobile_Char-optimised.png'
import Hero_maxMobile_Char_WEBP from '../images/hero/v3/Hero_maxMobile_Char-optimised.webp'
import Hero_minMobile_Char_WEBP from '../images/hero/v3/Hero_minMobile_Char-optimised.webp'
import Hero_minMobile_Char_PNG from '../images/hero/v3/Hero_minMobile_Char-optimised.png'

import Hero_maxMobile_JPG from '../images/hero/v4/Hero_maxMobile-optimised.jpeg'
import Hero_maxMobile_WEBP from '../images/hero/v4/Hero_maxMobile-optimised.webp'
import Hero_maxMobile_PNG from '../images/hero/v4/Hero_maxMobile-optimised.png'

import Hero_minMobile_JPG from '../images/hero/v4/Hero_minMobile-optimised.jpeg'
import Hero_minMobile_WEBP from '../images/hero/v4/Hero_minMobile-optimised.webp'
import Hero_minMobile_PNG from '../images/hero/v4/Hero_minMobile-optimised.png'

import Hero_maxDesktop_JPG from '../images/hero/v4/Hero_maxDesktop-optimised.jpeg'
import Hero_maxDesktop_PNG from '../images/hero/v4/Hero_maxDesktop-optimised.png'
import Hero_maxDesktop_WEBP from '../images/hero/v4/Hero_maxDesktop-optimised.webp'

import Hero_minDesktop_JPG from '../images/hero/v4/Hero_minDesktop-optimised.jpeg'
import Hero_minDesktop_PNG from '../images/hero/v4/Hero_minDesktop-optimised.png'
import Hero_minDesktop_WEBP from '../images/hero/v4/Hero_minDesktop-optimised.webp'
import Hero_Pattern_WEBP from '../images/other/ContactBG.webp'

import CustomGlobalButton from '../components/CustomGlobalButton'
import {useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../utils/scroll_into_view'
import useScreenDetector from '../hooks/useScreenDetector'
import {LoadingContext} from '../pages'

const HeresWhyText = ({className}) => {
	return (
		<h1 className={`${className ? className : ''} section-title font-lucky !mt-0`}>
			<span className='text-e_blue pr-4 xs:pr-8'>{"HERE'S"}</span>
			<span className='text-e_red'>{'WHY'}</span>
		</h1>
	)
}
const ImageLayer = ({
	fileType = 'image/jpeg' | 'image/png',
	className,
	Hero_minMobile_WEBP,
	Hero_maxMobile_WEBP,
	Hero_minDesktop_WEBP,
	Hero_maxDesktop_WEBP,
	Hero_minMobile,
	Hero_maxMobile,
	Hero_minDesktop,
	Hero_maxDesktop,
	onClick,
	onImageLoad,
}) => {
	const {isMobile, isMinDesktop, isTablet, isMinMobile, isDesktop} = useScreenDetector()

	return (
		<picture className='z-10 w-full '>
			{/* WebP sources */}
			{isMinMobile && <source type='image/webp' srcSet={Hero_minMobile_WEBP} />}
			{isMobile && <source type='image/webp' srcSet={Hero_maxMobile_WEBP} />}
			{(isMinDesktop || isTablet) && <source type='image/webp' srcSet={Hero_minDesktop_WEBP} />}
			{isDesktop && <source type='image/webp' srcSet={Hero_maxDesktop_WEBP} />}
			{/* JPEG/PNG sources */}
			{isMinMobile && <source type={fileType} srcSet={Hero_minMobile} />}
			{isMobile && <source type={fileType} srcSet={Hero_maxMobile} />}
			{(isMinDesktop || isTablet) && <source type={fileType} srcSet={Hero_minDesktop} />}
			{isDesktop && <source type={fileType} srcSet={Hero_maxDesktop} />}
			<img
				className={`${className} w-full hero-bg`}
				alt='Hero Background'
				loading='eager'
				onClick={onClick}
				fetchpriority='high'
				importance='high'
				decoding='sync'
				src={'/Loading.svg'} // Use the state for the src
				onLoad={(e) => {
					if (onImageLoad) {
						console.log('Image loaded successfully!') // Existing log
						const loadedSrc = e.target.currentSrc // Get the actual source used
						console.log(loadedSrc) // Log the image file name dynamically
						onImageLoad(true) // Call onImageLoad directly
					}
				}}
			/>
		</picture>
	)
}
const HeroSection = () => {
	// eslint-disable-next-line no-unused-vars
	const {setIsLoaded, isLoaded} = useContext(LoadingContext)
	// eslint-disable-next-line no-unused-vars
	const {isMobile, isMinDesktop, isTablet, isMinMobile, isDesktop} = useScreenDetector()

	// const [imgUrl, setImgUrl] = useState(isMobile ? BG_M_PNG : BG_D_PNG)

	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/contact?from=hero`)
	}
	const onImageLoad = () => {
		// console.log('Image loaded successfully!2')
		// Add more functions here
		// console.log('useEffect triggered on HeroSection onImageLoad, isLoaded:', isLoaded)
		setIsLoaded(true)
	}

	return (
		<div className='relative flex justify-center items-center mx-auto w-[100svw] flex-col ~max-w-screen-xl'>
			{/* Left Background */}
			{/* {isDesktop && (
				<div className='absolute left-0 top-0 w-full h-[85%] bg-[rgb(32,135,203)] z-0 -translate-x-full'>
					<img src={Hero_Pattern_WEBP} alt='Blue Pattern' className='w-full h-full object-cover' />
				</div>
			)} */}
			{/* Main Content */}
			{/* <div className='relative z-10'>
				<ImageLayer
					fileType={'image/jpeg'}
					className={'pointer-events-none h-full'}
					Hero_minMobile_WEBP={Hero_minMobile_WEBP}
					Hero_maxMobile_WEBP={Hero_maxMobile_WEBP}
					Hero_minDesktop_WEBP={Hero_minDesktop_WEBP}
					Hero_maxDesktop_WEBP={Hero_maxDesktop_WEBP}
					Hero_minMobile={Hero_minDesktop_JPG}
					Hero_maxMobile={Hero_minDesktop_JPG}
					Hero_minDesktop={Hero_minDesktop_JPG}
					Hero_maxDesktop={Hero_maxDesktop_JPG}
					onImageLoad={onImageLoad}
				/>
			</div> */}
			<div className='w-full min-h-[1px] relative flex justify-center items-center flex-col '>
				<ImageLayer
					fileType={'image/jpeg'}
					className={'pointer-events-none h-full'}
					Hero_minMobile_WEBP={Hero_minMobile_WEBP}
					Hero_maxMobile_WEBP={Hero_maxMobile_WEBP}
					Hero_minDesktop_WEBP={Hero_minDesktop_WEBP}
					Hero_maxDesktop_WEBP={Hero_maxDesktop_WEBP}
					Hero_minMobile={Hero_minDesktop_JPG}
					Hero_maxMobile={Hero_minDesktop_JPG}
					Hero_minDesktop={Hero_minDesktop_JPG}
					Hero_maxDesktop={Hero_maxDesktop_JPG}
					onImageLoad={onImageLoad}
				/>
				{/* button and show girl */}
				<ImageLayer
					fileType={'image/png'}
					className={'absolute bottom-0 hover:cursor-pointer'}
					Hero_minMobile_WEBP={Hero_minMobile_Char_WEBP}
					Hero_maxMobile_WEBP={Hero_maxMobile_Char_WEBP}
					Hero_minDesktop_WEBP={Hero_minDesktop_Char_WEBP}
					Hero_maxDesktop_WEBP={Hero_maxDesktop_Char_WEBP}
					Hero_minMobile={Hero_minDesktop_Char_PNG}
					Hero_maxMobile={Hero_minDesktop_Char_PNG}
					Hero_minDesktop={Hero_minDesktop_Char_PNG}
					Hero_maxDesktop={Hero_maxDesktop_Char_PNG}
					onClick={gotoContact}
				/>
			</div>
			{/* Right Background */}
			{/* {isDesktop && (
				<div className='absolute right-0 top-0 w-full h-[85%] bg-[rgb(167,10,23)] z-0 translate-x-full '>
					<img src={Hero_Pattern_WEBP} alt='Red Pattern' className='w-full h-full object-cover' />
				</div>
			)} */}
			{/* Other content */}
			{/* <div className={'absolute bottom-0 z-20'}>
				<HeresWhyText />
			</div> */}
		</div>
	)
}

export default HeroSection
